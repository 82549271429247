.nice-btn-issue {
  padding: 0;
  margin-bottom: 10px;
}

.nice-btn-issue-icon {
  padding: 5px;
  width: 30px;
  height: 30px;
}
